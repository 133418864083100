import { Show, Suspense } from 'solid-js';
import { BreadcrumbItem, Breadcrumbs, Container, Heading, Page, Section, TextLink } from '@troon/ui';
import { createAsync, Navigate, useLocation } from '@solidjs/router';
import { Title } from '@solidjs/meta';
import { cachedGet } from '@troon/api-client';
import { IconGolfCartCustom } from '@troon/icons/golf-cart-custom';
import { useUser } from '../../../providers/user';
import { Grid, GridFive, GridSeven } from '../../../components/layouts/grid';
import { gql } from '../../../graphql';
import { cachedQuery } from '../../../graphql/cached-get';
import { title } from './verify/_content';
import PriveTeeTimeForm from './components/prive-tee-time-form';

export default function VisaRequestTeeTime() {
	const user = useUser();
	const location = useLocation();
	const status = createAsync(() => getVisaStatus({}), { deferStream: true });
	const facilities = createAsync(() => getFacilities({}), { deferStream: false });

	return (
		<Suspense>
			<Show when={user()} fallback={<Navigate href={`/auth?redirect=${location.pathname}`} />}>
				<Suspense>
					<Show when={status()?.data}>
						{(status) => (
							<Show when={status().privateEligible} fallback={<Navigate href="/rewards/visa" />}>
								<Title>Request a Tee Time | Troon Privé Member Programs | Visa Troon Rewards | Troon</Title>

								<div class="h-full grow bg-neutral-100">
									<Container>
										<div class="pt-4">
											<Breadcrumbs>
												<BreadcrumbItem href="/rewards">Troon Rewards</BreadcrumbItem>
												<BreadcrumbItem href="/rewards/visa">Visa Golf Benefits</BreadcrumbItem>
												<BreadcrumbItem href={location.pathname}>Request a tee time</BreadcrumbItem>
											</Breadcrumbs>
										</div>

										<Page>
											<Grid>
												<GridFive>
													<Section>
														<IconGolfCartCustom class="size-24" />
														<Heading as="h1" size="h2">
															Request a tee time
														</Heading>

														<p>
															Your {title[status().programType]} card also gives access to over 20 high-end, private
															Troon Privé golf clubs. Play each club two times per year for $99 per player per round
															when you use your {title[status().programType]} card to book, and bring up to three guests
															at the same rate.
														</p>

														<p>
															If you have any questions about the program or are experiencing issues accessing these
															benefits, please <TextLink href="/about/contact">contact us</TextLink>.
														</p>
													</Section>
												</GridFive>

												<GridSeven>
													<PriveTeeTimeForm
														facilities={facilities()?.facilities.facilities ?? []}
														priveRequestType="VISA"
													/>
												</GridSeven>
											</Grid>
										</Page>
									</Container>
								</div>
							</Show>
						)}
					</Show>
				</Suspense>
			</Show>
		</Suspense>
	);
}

const facilityQuery = gql(`query visaPriveTeeTimeFacilities {
	facilities: facilitiesV3(supportsVisaPrive: true, sortBy: name) {
		facilities {
			id
			slug
			name
			timezone
		}
	}
}`);

const getFacilities = cachedQuery(facilityQuery, {});
const getVisaStatus = cachedGet('/v0/visa/status');
